.App {
  margin: 0px;
  min-height: 100vh;
  text-align: center;
  background-color: #1d1e33;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  align-items: center;
}
h1 {
  width: 80%;
  z-index: 1;
  text-align: center;
  color: white;
  font-size: 25px;
  margin-left: auto;
  margin-right: auto;
}
h2 {
  z-index: 1;
  text-align: left;
  color: white;
  margin-top: 2%;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
}
.logo-wrapper {
  z-index: 1;
  margin-top: 8%;
  display: flex;
  justify-content: center;
}
.logo {
  z-index: 1;
  width: 20%;
}

@mixin epic-sides() {
  // https://codepen.io/MichaelArestad/pen/qltuk
  position: relative;
  z-index: 1;

  &:before {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    height: 100%;
    z-index: -1;
    @content;
  }
}
.contact-form {
  width: 100%;
  .row {
    max-width: 100%;
    margin: 0 auto;
    padding: 60px 30px;
    background: transparent;
    @include epic-sides() {
      background: inherit;
    }
    text-align: center;

    &:first-child {
      padding: 40px 30px;
    }
    &:nth-child(2),
    &:nth-child(8),
    &:nth-child(10) {
      background: #134a46;
    }
    &:nth-child(3),
    &:nth-child(7) {
      background: #377d6a;
    }
    &:nth-child(4),
    &:nth-child(6) {
      background: #7ab893;
    }
    &:nth-child(5) {
      background: #b2e3af;
    }

    span {
      position: relative;
      display: inline-block;
      margin: 30px 10px;
    }
  }
  .basic-slide {
    display: inline-block;
    width: 215px;
    height: 21px;
    padding: 10px 0 10px 15px;
    font-family: "Open Sans", sans;
    font-weight: 400;
    color: #377d6a;
    background: #efefef;
    border: 0;
    border-radius: 3px;
    outline: 0;
    text-indent: 70px; // Arbitrary.
    transition: all 0.3s ease-in-out;

    &::-webkit-input-placeholder {
      color: #efefef;
      text-indent: 0;
      font-weight: 300;
    }

    + label {
      color: white;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      padding: 10px 15px;
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
      background: #1bb580;
      transition: all 0.3s ease-in-out;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }
  .basic-slide:focus,
  .basic-slide:active {
    color: #377d6a;
    text-indent: 0;
    background: #fff;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &::-webkit-input-placeholder {
      color: #aaa;
    }
    + label {
      transform: translateX(-100%);
    }
  }
}
$green: #1bb580;
$gray: #bbbbbb;
.container {
  width: 130px;
  height: 40px;
  text-align: center;
}
button {
  outline: none;
  height: 40px;
  text-align: center;
  width: 130px;
  border-radius: 5px;
  background: #fff;
  border: 2px solid $green;
  color: $green;
  letter-spacing: 1px;
  text-shadow: 0;
  font: {
    size: 12px;
    weight: bold;
  }
  cursor: pointer;
  transition: all 0.25s ease;
  &:hover {
    color: white;
    background: $green;
  }
  &:active {
    //letter-spacing: 2px;
    letter-spacing: 2px;
  }
  &:after {
    content: "SUBMIT";
  }
}
.onclic {
  cursor: default;
  border-radius: 60px;
  width: 40px;
  border-color: $gray;
  border-width: 3px;
  font-size: 0;
  border-left-color: $green;
  animation: rotating 2s 0.25s linear infinite;

  &:after {
    content: "";
  }
  &:hover {
    color: $green;
    background: white;
  }
}
.validate {
  cursor: default;
  font-size: 13px;
  color: white;
  background: $green;
  &:after {
    font-family: "FontAwesome";
    content: "\f00c";
  }
}
.validateFail {
  cursor: pointer;
  font-size: 13px;
  color: white;
  border-color: rgba(255, 38, 38, 0.788);
  background: rgba(255, 38, 38, 0.788);
  &:after {
    font-family: "FontAwesome";
    content: "\f00d";
  }
  &:hover {
    &:after {
      content: "SUBMIT";
    }
    background: $green;
    border-color: $green;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.error-wrapp {
  max-width: 215px;
  b {
    color: red;
    font-weight: normal;
    text-align: left;
  }
}

.left-leaf {
  z-index: 0;
  position: absolute;
  width: 20%;
  left: -5%;
  top: 25%;
  transform: rotate(5deg);
}
.right-artifact {
  z-index: 0;
  width: 44%;
  padding-top: 44%;
  height: 0%;
  position: absolute;
  background-color: #33334b;
  border-radius: 50%;
  bottom: -45%;
  right: -30%;
}
.space {
  width: 70px;
}
.socials {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    color: $green;
    font-size: 20px;
    margin-right: 10px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .logo-wrapper {
    z-index: 1;
    margin-top: 10%;
    display: flex;
    justify-content: center;
    height: 20%;
  }
  .logo {
    z-index: 1;
    width: 50%;
    object-fit: contain;
    height: 100%;
  }
  h1 {
    z-index: 1;
    color: white;
    margin-top: 10%;
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  h2 {
    z-index: 1;
    text-align: left;
    color: white;
    margin-top: 4%;
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .left-leaf {
    z-index: 0;
    position: absolute;
    width: 40%;
    left: -5%;
    top: 25%;
    transform: rotate(5deg);
  }
  .right-artifact {
    z-index: 0;
    width: 60%;
    padding-top: 60%;
    height: 0%;
    position: absolute;
    background-color: #33334b;
    border-radius: 50%;
    bottom: 0%;
    right: -30%;
  }
  .space {
    display: none !important;
  }
  .contact-form .row span {
    margin: 0px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .logo-wrapper {
    z-index: 1;
    margin-top: 10%;
    display: flex;
    justify-content: center;
  }
  .logo {
    z-index: 1;
    width: 50%;
  }
  h1 {
    z-index: 1;
    color: white;
    margin-top: 10%;
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  h2 {
    z-index: 1;
    text-align: left;
    color: white;
    margin-top: 4%;
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .left-leaf {
    z-index: 0;
    position: absolute;
    width: 40%;
    left: -5%;
    top: 25%;
    transform: rotate(5deg);
  }
  .right-artifact {
    z-index: 0;
    width: 60%;
    padding-top: 60%;
    height: 0%;
    position: absolute;
    background-color: #33334b;
    border-radius: 50%;
    bottom: 0%;
    right: -30%;
  }
  .space {
    display: none !important;
  }
  .contact-form .row span {
    margin: 0px !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .logo-wrapper {
    z-index: 1;
    margin-top: 15%;
    display: flex;
    justify-content: center;
  }
  .logo {
    z-index: 1;
    width: 30%;
  }
  h1 {
    z-index: 1;
    color: white;
    margin-top: 10%;
    font-size: 24px;
    margin-left: 5%;
    margin-right: 5%;
  }
  h2 {
    z-index: 1;
    text-align: left;
    color: white;
    margin-top: 4%;
    font-size: 18px;
    margin-left: 5%;
    margin-right: 5%;
  }
  .left-leaf {
    z-index: 0;
    position: absolute;
    width: 35%;
    left: -5%;
    top: 25%;
    transform: rotate(5deg);
  }
  .right-artifact {
    z-index: 0;
    width: 60%;
    padding-top: 60%;
    height: 0%;
    position: absolute;
    background-color: #33334b;
    border-radius: 50%;
    bottom: -10%;
    right: -30%;
  }
  .space {
    width: 100px;
  }
  .contact-form .row span {
    margin: 30px 10px !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .App {
    margin: 0px;
    min-height: 100vh;
    text-align: center;
    background-color: #1d1e33;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
  }
  h1 {
    z-index: 1;
    color: white;
    margin-top: 5%;
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
  }
  h2 {
    z-index: 1;
    text-align: left;
    color: white;
    margin-top: 2%;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
  }
  .logo-wrapper {
    z-index: 1;
    margin-top: 8%;
    display: flex;
    justify-content: center;
  }
  .logo {
    z-index: 1;
    width: 20%;
  }

  @mixin epic-sides() {
    // https://codepen.io/MichaelArestad/pen/qltuk
    position: relative;
    z-index: 1;

    &:before {
      position: absolute;
      content: "";
      display: block;
      top: 0;
      height: 100%;
      z-index: -1;
      @content;
    }
  }
  .contact-form {
    width: 100%;
    .row {
      max-width: 100%;
      margin: 0 auto;
      padding: 60px 30px;
      background: transparent;
      @include epic-sides() {
        background: inherit;
      }
      text-align: center;

      &:first-child {
        padding: 40px 30px;
      }
      &:nth-child(2),
      &:nth-child(8),
      &:nth-child(10) {
        background: #134a46;
      }
      &:nth-child(3),
      &:nth-child(7) {
        background: #377d6a;
      }
      &:nth-child(4),
      &:nth-child(6) {
        background: #7ab893;
      }
      &:nth-child(5) {
        background: #b2e3af;
      }

      span {
        position: relative;
        display: inline-block;
        margin: 30px 10px;
      }
    }
    .basic-slide {
      display: inline-block;
      width: 215px;
      height: 21px;
      padding: 10px 0 10px 15px;
      font-family: "Open Sans", sans;
      font-weight: 400;
      color: #377d6a;
      background: #efefef;
      border: 0;
      border-radius: 3px;
      outline: 0;
      text-indent: 70px; // Arbitrary.
      transition: all 0.3s ease-in-out;

      &::-webkit-input-placeholder {
        color: #efefef;
        text-indent: 0;
        font-weight: 300;
      }

      + label {
        color: white;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px 15px;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
        background: #1bb580;
        transition: all 0.3s ease-in-out;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }
    .basic-slide:focus,
    .basic-slide:active {
      color: #377d6a;
      text-indent: 0;
      background: #fff;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &::-webkit-input-placeholder {
        color: #aaa;
      }
      + label {
        transform: translateX(-100%);
      }
    }
  }
  $green: #1bb580;
  $gray: #bbbbbb;
  .container {
    width: 130px;
    height: 40px;
    text-align: center;
  }
  button {
    outline: none;
    height: 40px;
    text-align: center;
    width: 130px;
    border-radius: 5px;
    background: #fff;
    border: 2px solid $green;
    color: $green;
    letter-spacing: 1px;
    text-shadow: 0;
    font: {
      size: 12px;
      weight: bold;
    }
    cursor: pointer;
    transition: all 0.25s ease;
    &:hover {
      color: white;
      background: $green;
    }
    &:active {
      //letter-spacing: 2px;
      letter-spacing: 2px;
    }
    &:after {
      content: "SUBMIT";
    }
  }
  .onclic {
    cursor: default;
    border-radius: 60px;
    width: 40px;
    border-color: $gray;
    border-width: 3px;
    font-size: 0;
    border-left-color: $green;
    animation: rotating 2s 0.25s linear infinite;

    &:after {
      content: "";
    }
    &:hover {
      color: $green;
      background: white;
    }
  }
  .validate {
    cursor: default;
    font-size: 13px;
    color: white;
    background: $green;
    &:after {
      font-family: "FontAwesome";
      content: "\f00c";
    }
  }
  .validateFail {
    cursor: pointer;
    font-size: 13px;
    color: white;
    border-color: rgba(255, 38, 38, 0.788);
    background: rgba(255, 38, 38, 0.788);
    &:after {
      font-family: "FontAwesome";
      content: "\f00d";
    }
    &:hover {
      &:after {
        content: "SUBMIT";
      }
      background: $green;
      border-color: $green;
    }
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .error-wrapp {
    max-width: 215px;
    b {
      color: red;
      font-weight: normal;
      text-align: left;
    }
  }

  .left-leaf {
    z-index: 0;
    position: absolute;
    width: 20%;
    left: -5%;
    top: 25%;
    transform: rotate(5deg);
  }
  .right-artifact {
    z-index: 0;
    width: 44%;
    padding-top: 44%;
    height: 0%;
    position: absolute;
    background-color: #33334b;
    border-radius: 50%;
    bottom: -45%;
    right: -30%;
  }
  .space {
    width: 70px;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .logo-wrapper {
      z-index: 1;
      margin-top: 10%;
      display: flex;
      justify-content: center;
    }
    .logo {
      z-index: 1;
      width: 50%;
    }
    h1 {
      z-index: 1;
      color: white;
      margin-top: 10%;
      font-size: 20px;
      margin-left: 10px;
      margin-right: 10px;
    }
    h2 {
      z-index: 1;
      text-align: left;
      color: white;
      margin-top: 4%;
      font-size: 16px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .left-leaf {
      z-index: 0;
      position: absolute;
      width: 40%;
      left: -5%;
      top: 25%;
      transform: rotate(5deg);
    }
    .right-artifact {
      z-index: 0;
      width: 60%;
      padding-top: 60%;
      height: 0%;
      position: absolute;
      background-color: #33334b;
      border-radius: 50%;
      bottom: 0%;
      right: -30%;
    }
    .space {
      display: none !important;
    }
    .contact-form .row span {
      margin: 0px !important;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .App {
    margin: 0px;
    min-height: 100vh;
    text-align: center;
    background-color: #1d1e33;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
  }
  h1 {
    z-index: 1;
    color: white;
    margin-top: 3%;
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
  }
  h2 {
    z-index: 1;
    text-align: left;
    color: white;
    margin-top: 2%;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
  }
  .logo-wrapper {
    z-index: 1;
    margin-top: 8%;
    display: flex;
    justify-content: center;
  }
  .logo {
    z-index: 1;
    width: 20%;
  }

  @mixin epic-sides() {
    // https://codepen.io/MichaelArestad/pen/qltuk
    position: relative;
    z-index: 1;

    &:before {
      position: absolute;
      content: "";
      display: block;
      top: 0;
      height: 100%;
      z-index: -1;
      @content;
    }
  }
  .contact-form {
    width: 100%;
    .row {
      max-width: 100%;
      margin: 0 auto;
      padding: 60px 30px;
      background: transparent;
      @include epic-sides() {
        background: inherit;
      }
      text-align: center;

      &:first-child {
        padding: 40px 30px;
      }
      &:nth-child(2),
      &:nth-child(8),
      &:nth-child(10) {
        background: #134a46;
      }
      &:nth-child(3),
      &:nth-child(7) {
        background: #377d6a;
      }
      &:nth-child(4),
      &:nth-child(6) {
        background: #7ab893;
      }
      &:nth-child(5) {
        background: #b2e3af;
      }

      span {
        position: relative;
        display: inline-block;
        margin: 30px 10px;
      }
    }
    .basic-slide {
      display: inline-block;
      width: 215px;
      height: 21px;
      padding: 10px 0 10px 15px;
      font-family: "Open Sans", sans;
      font-weight: 400;
      color: #377d6a;
      background: #efefef;
      border: 0;
      border-radius: 3px;
      outline: 0;
      text-indent: 70px; // Arbitrary.
      transition: all 0.3s ease-in-out;

      &::-webkit-input-placeholder {
        color: #efefef;
        text-indent: 0;
        font-weight: 300;
      }

      + label {
        color: white;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px 15px;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
        background: #1bb580;
        transition: all 0.3s ease-in-out;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }
    .basic-slide:focus,
    .basic-slide:active {
      color: #377d6a;
      text-indent: 0;
      background: #fff;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &::-webkit-input-placeholder {
        color: #aaa;
      }
      + label {
        transform: translateX(-100%);
      }
    }
  }
  $green: #1bb580;
  $gray: #bbbbbb;
  .container {
    width: 130px;
    height: 40px;
    text-align: center;
  }
  button {
    outline: none;
    height: 40px;
    text-align: center;
    width: 130px;
    border-radius: 5px;
    background: #fff;
    border: 2px solid $green;
    color: $green;
    letter-spacing: 1px;
    text-shadow: 0;
    font: {
      size: 12px;
      weight: bold;
    }
    cursor: pointer;
    transition: all 0.25s ease;
    &:hover {
      color: white;
      background: $green;
    }
    &:active {
      //letter-spacing: 2px;
      letter-spacing: 2px;
    }
    &:after {
      content: "SUBMIT";
    }
  }
  .onclic {
    cursor: default;
    border-radius: 60px;
    width: 40px;
    border-color: $gray;
    border-width: 3px;
    font-size: 0;
    border-left-color: $green;
    animation: rotating 2s 0.25s linear infinite;

    &:after {
      content: "";
    }
    &:hover {
      color: $green;
      background: white;
    }
  }
  .validate {
    cursor: default;
    font-size: 13px;
    color: white;
    background: $green;
    &:after {
      font-family: "FontAwesome";
      content: "\f00c";
    }
  }
  .validateFail {
    cursor: pointer;
    font-size: 13px;
    color: white;
    border-color: rgba(255, 38, 38, 0.788);
    background: rgba(255, 38, 38, 0.788);
    &:after {
      font-family: "FontAwesome";
      content: "\f00d";
    }
    &:hover {
      &:after {
        content: "SUBMIT";
      }
      background: $green;
      border-color: $green;
    }
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .error-wrapp {
    max-width: 215px;
    b {
      color: red;
      font-weight: normal;
      text-align: left;
    }
  }

  .left-leaf {
    z-index: 0;
    position: absolute;
    width: 20%;
    left: -5%;
    top: 25%;
    transform: rotate(5deg);
  }
  .right-artifact {
    z-index: 0;
    width: 44%;
    padding-top: 44%;
    height: 0%;
    position: absolute;
    background-color: #33334b;
    border-radius: 50%;
    bottom: -45%;
    right: -30%;
  }
  .space {
    width: 70px;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .logo-wrapper {
      z-index: 1;
      margin-top: 10%;
      display: flex;
      justify-content: center;
    }
    .logo {
      z-index: 1;
      width: 50%;
    }
    h1 {
      z-index: 1;
      color: white;
      margin-top: 5%;
      font-size: 20px;
      margin-left: 10px;
      margin-right: 10px;
    }
    h2 {
      z-index: 1;
      text-align: left;
      color: white;
      margin-top: 4%;
      font-size: 16px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .left-leaf {
      z-index: 0;
      position: absolute;
      width: 40%;
      left: -5%;
      top: 25%;
      transform: rotate(5deg);
    }
    .right-artifact {
      z-index: 0;
      width: 60%;
      padding-top: 60%;
      height: 0%;
      position: absolute;
      background-color: #33334b;
      border-radius: 50%;
      bottom: 0%;
      right: -30%;
    }
    .space {
      display: none !important;
    }
    .contact-form .row span {
      margin: 0px !important;
    }
  }
}
